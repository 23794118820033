"use client";

import { ShareIcon } from "lucide-react";
import { usePathname } from "next/navigation";
import { Page } from "types";

import { AddNote } from "@/components/add-note";
import FeedbackFishWidget from "@/components/feedback-fish";
import ReferEarn from "@/components/refer-earn";
import { SaveButton } from "@/components/saved-pages";
import { Button } from "@/components/ui/button";

interface ActionsProps {
	page: Page;
}

const Actions = ({ page }: ActionsProps) => {
	const pathname = usePathname();
	const pageUrl = page?.url || pathname.replace(/\/+$/, "");
	const isDatabasePage = pathname?.startsWith("/database");

	return (
		<div className="flex w-full justify-around gap-1 md:flex-col md:space-y-4">
			{!isDatabasePage && (
				<>
					{/* Save */}
					<SaveButton
						variant="outline"
						className="justify-center gap-x-2 rounded-md font-medium md:w-full"
						page={page}
					/>

					{/* Add note */}
					<AddNote variant="outline" page={page} className="rounded-md" />
				</>
			)}

			{/* Send feedback */}
			<FeedbackFishWidget
				className="mt-0 w-auto gap-x-2 rounded-md font-medium md:w-full"
				variant="outline"
			>
				<span>Send feedback</span>
			</FeedbackFishWidget>

			{/* Share */}
			<ReferEarn url={pageUrl}>
				<Button
					size="lg"
					variant="outline"
					className="justify-center gap-x-2 rounded-md text-center font-medium md:w-full"
				>
					<ShareIcon className="size-4 lg:size-5" />
					<span className="hidden text-xs md:inline lg:text-sm xl:text-base">Share</span>
				</Button>
			</ReferEarn>
		</div>
	);
};

const MobileActionsToolbar = (props: ActionsProps) => {
	return (
		<div className="fixed bottom-0 left-0 right-0 z-50 flex justify-evenly bg-white p-4 md:hidden">
			<Actions {...props} />
		</div>
	);
};

export { Actions, MobileActionsToolbar };
